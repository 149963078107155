import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { StudioSettingsContext } from './Context';

const defaultValue = {
	value: {
		label: 'Default',
		leftMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftFirst: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftSecond: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftThird: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftFourth: { favoriteTab: '', isOpen: false, isCollapsed: false, drawTab: true },
		leftFifth: { favoriteTab: '', isOpen: false, isCollapsed: false },
		leftSixth: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightFirst: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightSecond: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightThird: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightFourth: { favoriteTab: '', isOpen: false, isCollapsed: false },
		rightMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		midRightMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		peopleMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		inviteMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		emailShareMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		smsShareMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		linkShareMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		groupsShareMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		qrShareMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		pollMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		auctionMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		drawMenu: { favoriteTab: '', isOpen: false, isCollapsed: false, drawMenu: true },
		toolsMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		elementsMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		libraryVideosMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		libraryAudiosMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		libraryGFXMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		libraryImagesMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		libraryPlaylistMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
		chatMenu: { favoriteTab: '', isOpen: false, isCollapsed: false, chatMenu: true },
		mediaManager: { favoriteTab: '', isOpen: false, isCollapsed: false },
		beeyouUsersMenu: { favoriteTab: '', isOpen: false, isCollapsed: false },
	},
};

export const fullScreenMenuItems = ['leftFirst', 'leftSecond', 'leftThird', 'leftFourth', 'leftFifth', 'leftSixth', 'rightFirst', 'rightSecond', 'rightThird', 'rightFourth', 'peopleMenu', 'inviteMenu', 'emailShareMenu', 'smsShareMenu', 'linkShareMenu', 'groupsShareMenu', 'qrShareMenu', 'pollMenu', 'auctionMenu', 'drawMenu', 'toolsMenu', 'elementsMenu', 'libraryVideosMenu', 'libraryAudiosMenu', 'libraryGFXMenu', 'libraryImagesMenu', 'libraryPlaylistMenu', 'chatMenu', 'mediaManager', 'beeyouUsersMenu'];

export const StudioSettingsProvider = ({ children }) => {
	const [studioSettings, setStudioSettings] = useState(defaultValue);
	const [previousOpenMenu, setPreviousOpenMenu] = useState(null);

	const context = useMemo(() => {
		const isMenuOpened = fullScreenMenuItems.some(
			(menuKey) => studioSettings.value[menuKey]?.isOpen,
		);

		return {
			handleChangeStudioSettings: (data) => {
				setStudioSettings({
					value: {
						...data,
					},
				});
			},
			handleChangeRightMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						rightMenu: {
							...prev.value.rightMenu,
							...data,
						},
					},
				}));
			},
			handleChangeMidRightMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						midRightMenu: {
							...prev.value.midRightMenu,
							...data,
						},
					},
				}));
			},
			handleChangeLeftMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						leftMenu: {
							...prev.value.leftMenu,
							...data,
						},
					},
				}));
			},
			handleChangeMenuItem: (menuKey, data) => {
				if (data.isOpen) {
					const currentOpenMenu = fullScreenMenuItems.find(
						(key) => studioSettings.value[key]?.isOpen,
					);
					setPreviousOpenMenu(currentOpenMenu || null);
				} else {
					setPreviousOpenMenu(null);
				}

				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						...(fullScreenMenuItems.includes(menuKey)
							? fullScreenMenuItems.reduce((acc, key) => ({
								...acc,
								[key]: key === menuKey
									? {
										...prev.value[key],
										...data,
										isOpen: data.isOpen !== undefined
											? data.isOpen
											: !prev.value[key].isOpen,
									}
									: { ...prev.value[key], isOpen: false },
							}), {})
							: { [menuKey]: { ...prev.value[menuKey], ...data } }
						),
					},
				}));
			},
			studioSettings,
			defaultValue,
			isMenuOpened,
			previousOpenMenu,
		};
	}, [studioSettings, previousOpenMenu]);

	return (
		<StudioSettingsContext.Provider value={context}>
			{children}
		</StudioSettingsContext.Provider>
	);
};

StudioSettingsProvider.propTypes = {
	children: PropTypes.node,
};

StudioSettingsProvider.defaultProps = {
	children: undefined,
};
