// @ts-check

import { useQuery } from 'react-query';

import * as surveyApi from '../../api/survey';
import { axiosQueryWrapper } from '../utils/axios-wrapper';

export const RSVP_QUERY_KEYS = {
	fetchPublicRsvp: (
		/** @type {string} */
		channelId,
	) => ['api', 'channel', 'fetch', 'rsvp', { channelId }],
};

/**
 * @param {string} channelId
 */
export const useFetchPublicRsvps = (channelId) => useQuery(
	RSVP_QUERY_KEYS.fetchPublicRsvp(channelId),
	axiosQueryWrapper(surveyApi.getPublicRsvps, channelId),
);
