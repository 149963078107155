// @ts-check

import { api } from './api';

/** @typedef {import('./surveyTemplate.dto').ISurveyTemplateDto} ISurveyTemplateDto */

export const API_SURVEYS_PATH = '/surveys';

export const fetchSurveyTemplates = (
	isArchived,
	itemsPerPage,
	currentPage,
	sortValue,
	isRsvp,
) => api.get(
	`${API_SURVEYS_PATH}`,
	{
		params: {
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
			isRsvp,
		},
	},
);

export const focusSurveyTemplate = (surveyTemplateId) => api.post(`${API_SURVEYS_PATH}/focus/${surveyTemplateId}`);

export const createSurveyTemplate = (surveyTemplateData) => api.post(`${API_SURVEYS_PATH}`, surveyTemplateData);

export const updateSurveyTemplate = (surveyTemplateId, surveyTemplateData) => api.put(`${API_SURVEYS_PATH}/${surveyTemplateId}`, surveyTemplateData);

export const deleteSurveyTemplate = (surveyTemplateId) => api.delete(`${API_SURVEYS_PATH}/${surveyTemplateId}`);

export const restoreSurveyTemplate = (surveyTemplateId) => api.put(`${API_SURVEYS_PATH}/${surveyTemplateId}/restore`);

export const getSurveyBySurveyTemplateAndChannel = (templateId, channelId) => api.get(`${API_SURVEYS_PATH}/overview/${templateId}/${channelId}`);

export const beginSurvey = (surveyTemplateCode) => api.post(`${API_SURVEYS_PATH}/begin/${surveyTemplateCode}`);

export const answerQuestion = (surveyId, questionId, answer) => api.put(`${API_SURVEYS_PATH}/${surveyId}/answer/${questionId}`, answer);

export const endSurvey = (surveyId) => api.put(`${API_SURVEYS_PATH}/end/${surveyId}`);

export const getReport = (surveyTemplateId) => api.get(`${API_SURVEYS_PATH}/report/${surveyTemplateId}`);

export const getSurvetTemplateParticipants = (surveyTemplateId) => api.get(`${API_SURVEYS_PATH}/people/${surveyTemplateId}`);

export const getReportCSV = (surveyTemplateId) => api.get(`${API_SURVEYS_PATH}/csv/report/${surveyTemplateId}`);

/**
 * @param {string} channelId
 * @returns {Promise<import('axios').AxiosResponse<ISurveyTemplateDto[]>>}
 */
export const getPublicRsvps = (channelId) => api.get(`${API_SURVEYS_PATH}/publicRsvp/${channelId}`);
