import { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalScreen } from '../../Modal/Screen/Screen';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { SurveyModalTitle } from './ModalTitle';
import { useSurveyTemplate } from '../Providers/SurveyTemplateProvider';
import { Tabs } from './SurveyTemplateModalTabs';
import { ModalLazyContent } from '../../Modal/LazyContent';

const SurveyTemplateModalContent = lazy(() => import('./SurveyTemplateModalContent'));

export const SurveyTemplateModal = () => {
	const { t } = useTranslation();
	const {
		isOpenSurveyTemplateModal,
		handleCloseSurveyTemplateModal,
		focusSurveyTemplate,
		initialTab,
		isRsvp,
	} = useSurveyTemplate();

	return focusSurveyTemplate ? (
		<ModalScreen
			className="p-0"
			bodyClassName="p-0"
			isOpen={isOpenSurveyTemplateModal}
			onClose={handleCloseSurveyTemplateModal}
			header={(
				<ModalScreenHeader
					onClose={handleCloseSurveyTemplateModal}
					onBack={handleCloseSurveyTemplateModal}
					title={(
						<>
							{initialTab === Tabs.PEOPLE && <SurveyModalTitle title={t('Modal.SurveyTemplateModal.respodents')} surveyTitle={focusSurveyTemplate.title} />}
							{initialTab === Tabs.SURVEY_TEMPLATE && <SurveyModalTitle title={focusSurveyTemplate.title || t(`Modal.SurveyTemplateModal.${isRsvp ? 'newRsvp' : 'newSurvey'}`)} />}
							{initialTab === Tabs.REPORT && <SurveyModalTitle title={t('Modal.SurveyTemplateModal.results')} surveyTitle={focusSurveyTemplate.title} />}
						</>
					)}
				/>
			)}
		>
			<ModalLazyContent>
				<SurveyTemplateModalContent />
			</ModalLazyContent>
		</ModalScreen>
	) : null;
};
