import PropTypes from 'prop-types';
import { lazy, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ModalScreen } from '../../Modal/Screen/Screen';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { StudioScheduleManagerTab, StudioScheduleModal, useStudioSchedule } from '../Context';
import { ModalLazyContent } from '../../Modal/LazyContent';
import { useFetchManyStudioScheduleSoon } from '../../../api-hooks/studio/schedule';
import { ModalLoadingTabPane } from '../../Modal/LoadingTabPane';
import { StudioScheduleManagerActiveStudios } from './ActiveStudios';

const StudioScheduleManagerEditModalContent = lazy(() => import('./EditModalContent'));

export const StudioScheduleManagerEditModal = ({
	isOpen,
	openModal,
	options,
	studioOwnerId,
}) => {
	const { t } = useTranslation();
	const { closeModal } = useStudioSchedule();
	const [tab, setTab] = useState(StudioScheduleManagerTab.EDIT);
	const [forceContinue, setForceContinue] = useState(false);
	const { data: studios = [], isLoading } = useFetchManyStudioScheduleSoon();

	const showActiveStudios = (
		!isLoading
		&& (studios?.length > 0 && !forceContinue)
	);

	const showEditForm = (
		!isLoading
		&& !showActiveStudios
	);

	useEffect(() => {
		setForceContinue(false);
	}, [studios]);

	return (
		<ModalScreen
			contentClassName="bg-gray"
			bodyClassName="pt-0"
			header={(
				<ModalScreenHeader
					noBorder
					onClose={tab === StudioScheduleManagerTab.EDIT_BANNER ? undefined : closeModal}
				>
					{tab === StudioScheduleManagerTab.EDIT_BANNER && (
						<header className="d-flex align-items-center">
							<Button
								className="btn-pill d-40 p-0"
								color="neutral-secondary"
								onClick={() => { setTab(StudioScheduleManagerTab.EDIT); }}
								title={t('EditModal.back')}
							>
								<span className="btn-wrapper--icon">
									<FaArrowLeft />
								</span>
							</Button>
							<h2 className="ml-2 h4 mb-0">{t('EditModal.bannerAndDesc')}</h2>
						</header>
					)}
				</ModalScreenHeader>
			)}
			isOpen={isOpen}
			onClose={tab === StudioScheduleManagerTab.EDIT_BANNER
				? () => setTab(StudioScheduleManagerTab.EDIT)
				: closeModal}
		>
			<ModalLazyContent>
				<div
					className={clsx(
						'h-100 w-100',
						{ 'd-none': !showEditForm }, // use d-none to load the lazy component
					)}
				>
					<StudioScheduleManagerEditModalContent
						options={options}
						setTab={setTab}
						studioOwnerId={studioOwnerId}
						tab={tab}
					/>
				</div>
				{isLoading && <ModalLoadingTabPane />}
				{showActiveStudios && (
					<StudioScheduleManagerActiveStudios
						closeModal={closeModal}
						onForceContinue={() => setForceContinue(true)}
						onSelectStudio={(studioId) => {
							setForceContinue(true);
							openModal(
								StudioScheduleModal.EDIT,
								studioId,
								undefined,
								{ forceStartImmediately: true },
							);
						}}
						studios={studios}
					/>
				)}
			</ModalLazyContent>
		</ModalScreen>
	);
};

StudioScheduleManagerEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	openModal: PropTypes.func.isRequired,
	options: PropTypes.shape({
		forceStartImmediately: PropTypes.bool,
	}),
	studioOwnerId: PropTypes.string,
};

StudioScheduleManagerEditModal.defaultProps = {
	options: {},
	studioOwnerId: undefined,
};
